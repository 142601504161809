<template>
	<div class='sl-login-modal-bg'>
		<div class='select-mailbox-modal-container'>
			<div class='select-mailbox-modal-wrapper'>
				<div class='sl-select-mailbox-title-container'>
					选择邮箱
				</div>

				<template v-for="item in this.availableMailbox" :key="item">
					<div class="mailbox-option" @click="onSelect(item)">
						<img v-if="this.selectedMail === item" class="mailbox-option-radio" src="../images/radio_selected_white.svg" alt="radio">
						<img v-else class="mailbox-option-radio" src="../images/radio_unselected_white.svg" alt="radio">
						<span>{{item}}</span>
					</div>
				</template>

<!--				<div class='sl-login-btn-container-login-modal'>-->
<!--					<div class="select-mailbox-cancel-btn" @click="onCancel">取消</div>-->
<!--					<div class="select-mailbox-confirm-btn" @click="onConfirm">确定</div>-->
<!--					<a-button type="primary" size="large" @click="onLoginConfirm">-->
<!--						确定-->
<!--					</a-button>-->
<!--					<a-button type="primary" size="large" @click="onLoginConfirm">-->
<!--						取消-->
<!--					</a-button>-->
<!--				</div>-->
			</div>
		</div>
	</div>

</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: 'SelectMailboxModal',
	data() {
		return {
			availableMailbox: [
				'@gmail.com',
				'@qq.com',
				'@163.com',
				'@126.com',
				'@hotmail.com',
				'@sina.com',
				'@sohu.com',
				'@msn.com',
				'@yahoo.com',
				'@sogou.com',
				'@outlook.com',
				'@live.com',
				'@icloud.com'
			],
			selectedMail: null
		}
	},
	props: ['selected'],
	beforeMount() {
		console.log(`this.selected: ${this.selected}`)
		this.selectedMail = this.selected
	},
	methods: {
		onConfirm() {
			this.$emit('onConfirm', this.selectedMail);
		},
		onCancel() {
			this.$emit('onCancel');
		},
		onSelect(item) {
			this.selectedMail = item
			this.$emit('onConfirm', this.selectedMail);
		}
	}
}
</script>

<style scoped>

.select-mailbox-modal-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 1em 1.6em;
}

.mailbox-option{
	color: #E6D5B8;
	font-size: 1.6em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.sl-select-mailbox-title-container {
	font-size: 1.6em;
	color: #E6D5B8;
	margin-bottom: 0.2em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.mailbox-option-radio{
	width: 1em;
	margin-right: 1em;
}

.select-email-btn{
	background-color: transparent;
	color: #FF8900;
	font-size: 1.2em;
	padding: 0 0.4em;
	line-height: 1.2em;
	display: flex;
	align-items: center;
	border: 1px solid #FF8900;
	border-radius: 4px;
}

@media only screen and (max-width: 900px) {
	.select-mailbox-modal-container {
		width: 95% !important;
	}
}

.select-mailbox-modal-container {
	background-color: #1f1f35;
	width: 40em;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 0.4em;
	top: 50%;
}

.sl-login-btn-container-login-modal {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	align-items: center;
	justify-content: end;
}

.sl-login-btn-confirm-login-modal {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	cursor: pointer;
	user-select: none;
}

.sl-login-btn-confirm-login-modal:active {
	transform: scale(0.9);
}

.sl-login-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*backdrop-filter: blur(8px) opacity(100%) brightness(90%);*/
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
}

.sl-login-modal {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, 0%);
	left: 50%;
	width: 48em;
	top: 5em;
	border-radius: 1em;
	/*border: 1px solid rgba(255, 137, 0, 0.44);*/
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
}

.sl-login-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em 3em;
}

.sl-login-modal-title {
	font-size: 1.6em;
	width: 100%;
	text-align: center;
	color: #FF8900;
}

.sl-login-modal-field-label {
	font-size: 1.4em;
	margin-top: 0.4em;
	color: #FF8900;
}

.sl-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-login-input-email, .sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	color: #FF8900;
	font-size: 1.4em;
	background: transparent !important;
	margin-top: 0.4em;
	flex: 1 1;
	padding: 0 !important;
}

input.sl-login-input:focus, .sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-login-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-login-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	width: 100%;
}

.sl-login-btn-confirm-login-modal {
	background-color: #FF8900;
	font-size: 1.4em;
	color: #3D3020;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	cursor: pointer;
}

.sl-login-btn-cancel-login-modal {
	background-color: #3D3020;
	font-size: 1.4em;
	color: #FF8900;
	padding: 0.4em 1.2em;
	border-radius: 0.2em;
	border: 1px solid #FF8900;
	margin-right: 3em;
	cursor: pointer;
}

input.sl-login-input:focus {
	border-bottom: 2px solid #FF8900;
}
.select-mailbox-confirm-btn{
	background-color: #FF8900;
	font-size: 1.4em;
	padding: 0.2em 0.6em;
	line-height: 1.4em;
	color: #3D3020;
	border-radius: 4px;
}
.select-mailbox-cancel-btn{
	background-color: transparent;
	border: 1px solid #FF8900;
	font-size: 1.4em;
	padding: 0.2em 0.6em;
	line-height: 1.4em;
	color: #FF8900;
	border-radius: 4px;
	margin-right: 1.2em;
}

.select-mailbox-cancel-btn:active, .select-mailbox-confirm-btn:active {
	transform: scale(0.9);
}
</style>