import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import DashboardPage from './pages/DashboardPage.vue';
import MySubscriptionPage from './pages/sub/MySubscriptionPage.vue';
import ManualPage from './pages/sub/ManualPage.vue';
import PlanPage from './pages/sub/PlanPage.vue';
import ReferFriendPage from './pages/sub/ReferFriendPage.vue';
import USDTPaymentPage from './pages/sub/USDTPaymentPage.vue';
import ContactPage from './pages/sub/ContactPage.vue';
import IosManualPage from './pages/IosManualPage.vue';
import ReferencePage from './pages/ReferencePage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: LoginPage, name: 'login'},
		{path: '/login', component: LoginPage, name: 'login'},  {
			path: '/:pathMatch(.*)*',
			redirect: '/dashboard' // 重定向到默认路径
		},
		{
			path: '/dashboard',
			component: DashboardPage,
			name: 'dashboard',
			redirect: '/dashboard/mysub', // 默认重定向到 /dashboard/mysub
			children: [
				{
					path: 'mysub',
					name: 'mysub',
					component: MySubscriptionPage
				},
				{
					path: 'manual',
					name: 'manual',
					component: ManualPage
				},
				{
					path: 'plan',
					name: 'plan',
					component: PlanPage
				},
				{
					path: 'referFriend',
					name: 'referFriend',
					component: ReferFriendPage
				},
				{
					path: 'contact',
					name: 'contact',
					component: ContactPage
				}
			]
		},
		{
			path: '/usdtPayment',
			name: 'usdtPayment',
			component: USDTPaymentPage
		},
		{
			path: '/iosManual',
			name: 'iosManual',
			component: IosManualPage
		},
		{
			path: '/referencePage',
			name: 'referencePage',
			component: ReferencePage
		}
	]
});

export default router;