<template>

	<div class="sub-view-container">
		<transition name="fade">
			<PaymentMethodsModal v-if="showPaymentMethodsModal" @closePaymentModal="onClosePaymentModal"
			                     @confirmPaymentMethod="onConfirmPaymentMethod"
			                     :availablePaymentMethods="availablePaymentMethods"></PaymentMethodsModal>
		</transition>
		<sub-view-header :title="'我的订阅'"/>
		<div class="sub-view-content" v-if="!isSubscriptionAvailable && !couponId">
			<img src="../../images/empty.svg" alt="empty" class="my-sub-empty-icon">
			<span class="my-sub-empty-text">您当前没有订阅</span>
			<a-button type="primary" class="my-sub-go-to-buy" size="large" @click="onGoToBuy">
				查看订阅套餐
				<template #icon>
					<ArrowRightOutlined class="my-sub-go-to-buy-btn-icon"/>
				</template>
			</a-button>
		</div>

		<div class="sub-coupon-content" v-if="!isSubscriptionAvailable && couponId">
			<div class="sub-coupon-text">{{ couponText }}</div>
			<a-button type="primary" class="my-sub-go-to-buy" size="large" @click="redeemCoupon">
				领取卡券
			</a-button>
		</div>


		<div class="subscription-display-container" v-if="isSubscriptionAvailable">
			<!--			<div class="subscription-summary-container">-->
			<div class="subscription-display-row">注册邮箱：{{ this.email }}</div>
			<div class="subscription-display-row">订阅类型：<img style="width: 1em;margin-right: 0.2em;"
			                                                src="../../images/diamond.svg"
			                                                alt="" v-if="isDiamond"/>{{ getPlanTypeDisplayString }}
			</div>
			<div class="subscription-display-row" v-if="!isPlanExpired">订阅剩余时间：{{ getPlanExpireTimeString() }}</div>
			<div class="subscription-display-row" v-if="isPlanExpired">订阅剩余时间：<span style="color: #fa541c;">已过期</span>
			</div>
			<div class="subscription-display-row" v-if="!isPlanExpired">流量重置时间：{{
					getNextBandwidthRestTime()
				}}
			</div>
			<div class="subscription-display-row" v-if="isPlanExpired">流量重置时间：无</div>
			<div class="subscription-display-row bandwidth-usage-container">
				<div>流量使用：</div>
				<a-progress :percent="getBandwidthUsagePercentage()" style="width: fit-content;margin-right: 1em;}"
				            :stroke-color="'#FF8900'" :trail-color="'#793c00'" :steps="20" :show-info="false"/>
				<div>{{ getBandwidthDisplayString(bandwidthUsage) }} /
					{{ getBandwidthDisplayString(bandwidthLimit) }}
				</div>
			</div>
			<div class="subscription-display-row">
				<a-button type="primary" size="middle" @click="extendPlan">
					套餐续费
				</a-button>
				<!--				<a-popconfirm-->
				<!--					:title="'升级您当前的套餐需要支付差额 ￥' + this.upgradePayAmount + '元，确认升级？'"-->
				<!--					ok-text="确认"-->
				<!--					cancel-text="取消"-->
				<!--					class="airport-dashboard-popconfirm"-->
				<!--					@confirm="upgradePlan"-->
				<!--					@cancel="cancel">-->
				<!--					<a-button type="primary" size="middle" style="margin-left: 1em;" @click="getUpgradePlanInfo" v-if="!isDiamond">-->
				<!--						升级套餐-->
				<!--					</a-button>-->
				<!--				</a-popconfirm>-->

				<a-button type="primary" size="middle" @click="updateSubscription" style="margin-left: 1em;">
					刷新订阅信息
				</a-button>
			</div>
			<!--			</div>-->
			<div class="sl-hr" style="margin: 2em 0;"></div>
			<div class="subscription-link-title">订阅链接:</div>
			<div class="subscription-link-row">
				<div class="subscription-link-wrapper">{{ this.subscriptionLink }}</div>
				<!--				<img src="../../images/copy.svg" alt="copy" style="width: 1em;cursor: pointer;margin-left: 0.4em;"-->
				<!--				     @click="copySubscriptionLink">-->
				<a-button type="primary" size="middle" class="copy-subscription-link-btn" @click="copySubscriptionLink">
					复制链接
				</a-button>
			</div>
			<div class="qr-canvas-wrapper">
				<canvas id="canvas" style="margin-top: 1em;"/>
			</div>

			<div class="sub-link-attention"><img src="../../images/attention.svg" alt="attention"
			                                     style="width: 1em;margin-right: 0.4em;">请注意：这是您的私有链接，请勿分享给陌生人。
			</div>

			<div class="sl-hr" style="margin: 2em 0;"></div>
			<div class="subscription-link-title" style="color: #E45826;">客户端下载:</div>
			<div class="client-app-container">

				<div class="client-app-table">
					<div class="client-app-row">
						<div class="client-app-table-title-container">
							<div class="client-app-table-title">
								<img src="../../images/os/os_and.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								安卓系统
							</div>

						</div>


						<div @click="onV2rayNGDownload" class="app-download-container">
							<div class="client-app-table-title">
								<img src="../../images/clients/v2rayng.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								v2rayNG
							</div>

						</div>


						<div class="app-download-container"  @click="onClashForAndroidDownload">
							<div class="client-app-table-title">
								<img src="../../images/clients/clash.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								clash for Android
							</div>

						</div>
					</div>
					<div class="client-app-row">
						<div class="client-app-table-title-container">
							<div class="client-app-table-title">
								<img src="../../images/os/os_ios.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								ios系统
							</div>
						</div>


						<div @click="onShadowrocketDownload" class="app-download-container">
							<div class="client-app-table-title">
								<img src="../../images/clients/shadowrocket.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								Shadowrocket
							</div>

						</div>

					</div>
					<div class="client-app-row">
						<div class="client-app-table-title-container">

								<div class="client-app-table-title">
									<img src="../../images/os/os_macos.svg" alt="os_android" class="client-app-table-os">
								</div>
								<div class="client-app-download">
									MacOS系统
								</div>
						</div>

						<div class="app-download-container">
							<a-popover title="请选择下载类型" trigger="click">
								<template #content>
									<div class="mac-download-system-pick-container">
										<a-button type="primary" size="middle" @click="onMacDownload('INTEL')">
											Intel芯片下载
										</a-button>
										<a-button type="primary" size="middle" style="margin-left: 1em;"
										          @click="onMacDownload('ARM')">
											苹果芯片下载
										</a-button>
									</div>

								</template>
							<div class="client-app-table-title">
								<img src="../../images/clients/v2rayU.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								v2rayU
							</div>

							</a-popover>
						</div>

						<div class="app-download-container" @click="onClashXDownload">
							<div class="client-app-table-title">
								<img src="../../images/clients/clash.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								ClashX
							</div>

						</div>

					</div>
					<div class="client-app-row">
						<div class="client-app-table-title-container">
							<div class="client-app-table-title">
								<img src="../../images/os/os_win.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								Windows系统
							</div>
						</div>

						<div class="app-download-container" @click="onV2rayNDownload">
							<div class="client-app-table-title">
								<img src="../../images/clients/v2rayN.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								v2rayN
							</div>

						</div>
						<div class="app-download-container" @click="onClashForWindowsDownload">
							<div class="client-app-table-title">
								<img src="../../images/clients/clash.svg" alt="os_android" class="client-app-table-os">
							</div>
							<div class="client-app-download">
								Clash for windows
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import SubViewHeader from "../../components/SubViewHeader.vue";
import {ArrowRightOutlined} from '@ant-design/icons-vue';
import {clickThrottle, formatDateString, getRequest, postRequest, reportVisit, startDownload} from "@/utils/methods";
import {localValue} from "@/utils/constants";
import QRCode from 'qrcode'
import PaymentMethodsModal from "../../components/PaymentMethodsModal.vue";
import copy from "copy-to-clipboard";

const {v4} = require('uuid');

export default {
	name: "MySubscriptionPage",
	components: {
		SubViewHeader,
		ArrowRightOutlined,
		PaymentMethodsModal
	},
	data() {
		return {
			email: '',
			isSubscriptionAvailable: false,
			bandwidthLimit: 0,
			planType: '',
			bandwidthUsage: 0,
			planExpireTimeString: '',
			planExpireTimestamp: 0,
			bandwidthResetDay: 0,
			planList: [],
			isDiamond: false,
			subscriptionLink: '',
			upgradePayAmount: 0,
			planRemainDays: 0,
			showPaymentMethodsModal: false,
			availablePaymentMethods: [],
			isPlanExpired: false,
			nextBandwidthResetTime: '',
			couponId: '',
			couponText: '',
			planTypeDisplayString: ''
		}
	},
	mounted() {
		this.refreshInfo()
	},
	computed: {

		getPlanTypeDisplayString() {
			this.getPlanTypeDisplay()
			return this.planTypeDisplayString
		}
	},
	methods: {
		onGoToBuy() {
			this.$emit("goToBuy");
		},
		getBandwidthDisplayString(input) {
			if (!input) {
				return '0GB'
			}
			return (input / 1024 / 1024 / 1024).toFixed(2) + 'GB'
		},
		getPlanExpireTimeString() {

			// const date = new Date(this.planExpireTimestamp * 1000);
			const expireDate = this.planExpireTimestamp * 1000
			const now = Date.now()

			const diff = expireDate - now

			const day = Math.floor(diff / (24 * 3600 * 1000))
			const hour = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000))
			const min = Math.floor((diff % (3600 * 1000)) / (60 * 1000))

			// const year = date.getFullYear();
			// const month = String(date.getMonth() + 1).padStart(2, '0');
			// const day = String(date.getDate()).padStart(2, '0');
			// const hours = String(date.getHours()).padStart(2, '0');
			// const minutes = String(date.getMinutes()).padStart(2, '0');
			// const seconds = String(date.getSeconds()).padStart(2, '0');

			return `${day}天${hour}小时${min}分`;

		},
		getPlanTypeDisplay() {

			if (!this.planList) {
				this.planTypeDisplayString = ''
				return
			}
			if (!Array.isArray(this.planList) || this.planList.length === 0) {
				this.planTypeDisplayString = ''
				return
			}
			if (!this.planType) {
				this.planTypeDisplayString = ''
				return
			}

			this.isDiamond = false
			for (const item of this.planList) {
				if (item.code === this.planType) {
					this.isDiamond = item.isDiamond

					this.planTypeDisplayString = item.title
					return

				}
			}
			this.planTypeDisplayString = '试用'

		},
		getBandwidthUsagePercentage() {
			if (this.bandwidthUsage === 0) {
				return 0
			} else {
				return (this.bandwidthUsage / this.bandwidthLimit * 100).toFixed(2)
			}
		},
		copySubscriptionLink() {

			const copyResult = copy(this.subscriptionLink);
			if (copyResult === true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.success({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}

		},
		onV2rayNGDownload() {
			if (!clickThrottle('onV2rayNGDownload')) return
			this.$message.info({
				content: '开始下载安卓客户端',
				duration: 3 // 设置持续时间为3秒
			});
			startDownload('https://tools.alphacloud.cc/android_client.zip')
			reportVisit('client_download_android')
		},
		onClashForAndroidDownload() {
			if (!clickThrottle('onClashForAndroidDownload')) return
			this.$message.info({
				content: '开始下载Clash for Android客户端',
				duration: 3 // 设置持续时间为3秒
			});
			startDownload('https://tools.alphacloud.cc/clash_for_android.apk')
			reportVisit('client_download_android')
		},
		onV2rayNDownload() {
			if (!clickThrottle('onV2rayNDownload')) return
			this.$message.info('开始下载Windows客户端');
			startDownload('https://tools.alphacloud.cc/windows_client.zip')
			reportVisit('client_download_windows')
		},
		onClashXDownload() {
			if (!clickThrottle('onClashXDownload')) return
			this.$message.info('开始下载ClashX客户端');
			startDownload('https://tools.alphacloud.cc/ClashX.zip')
			reportVisit('client_download_mac')
		},
		onClashForWindowsDownload() {
			if (!clickThrottle('onClashForWindowsDownload')) return
			this.$message.info('开始下载Clash for Windows客户端');
			startDownload('https://tools.alphacloud.cc/clash_for_windows.zip')
			reportVisit('client_download_windows')
		},
		onMacDownload(type) {
			if (!clickThrottle('onMacDownload')) return
			if (type === 'INTEL') {
				this.$message.info('开始下载Mac客户端');
				startDownload('https://tools.alphacloud.cc/V2rayU-64.dmg')
				reportVisit('client_download_mac')

			} else {
				this.$message.info('开始下载Mac客户端');
				startDownload('https://tools.alphacloud.cc/V2rayU-arm64.dmg')
				reportVisit('client_download_mac')
			}
		},
		onShadowrocketDownload () {

			reportVisit('client_download_ios')
			// setTimeout(() => {
			// 	window.open('https://www.alphacloud.cc/iosManual', '_blank')
			// })
			setTimeout(() => {
				window.open('https://apps.apple.com/us/app/shadowrocket/id932747118', '_blank');
			})
		},
		extendPlan() {
			this.$router.push('/dashboard/plan');
		},
		getUpgradePlanInfo() {
			getRequest('airport/payment/getUpgradePlanInfo', (result, data) => {
				if (!data) {
					return
				}

				if (data.code === 200) {
					this.upgradePayAmount = data.msg.amount
				}

			})
		},
		upgradePlan() {
			const productId = 'UPGRADE_PLAN'
			postRequest('airport/payment/getAvailablePaymentMethods', {productId: productId}, (result, data) => {
				if (result !== 'success') {
					this.$message.error('获取支付方式失败');
					return
				}

				if (data.code === 200) {
					this.availablePaymentMethods = data.msg.paymentMethods
				}
			})

			// this.selectedProductId = productId
			this.showPaymentMethodsModal = true


		},
		onConfirmPaymentMethod(paymentMethod) {
			const productId = 'UPGRADE_PLAN'
			if (paymentMethod === 'USDT') {
				postRequest('airport/payment/createTransaction', {planType: productId}, (result, data) => {

					if (result !== 'success') {
						this.$message.error('获取支付链接失败');
						return
					}

					if (data.code === 200 && data.msg.transactionId) {
						this.$router.push(`/usdtPayment?id=${data.msg.transactionId}`);

					}
				})
				return
			}

			if (paymentMethod === 'CREDITCARD') {
				const windowReference = window.open('', '_blank');
				postRequest('airport/payment/getPaymentUrl', {planType: productId}, (result, data) => {
					if (result !== 'success') {
						this.$message.error('获取支付链接失败');
						return
					}

					if (data.code === 200 && data.msg.paymentUrl) {
						windowReference.location = data.msg.paymentUrl;
					}
				})
				return
			}
			let postPaymentMethod
			if (paymentMethod === 'ALIPAY') {
				postPaymentMethod = 'alipay'
			} else if (paymentMethod === 'WECHATPAY') {
				postPaymentMethod = 'wechatpay'
			} else {
				this.$message.error('请选择支付方式');
				return
			}
			const windowReference = window.open('', '_blank');
			postRequest('airport/payment/createPayment', {
				paymentMethod: postPaymentMethod,
				planType: productId
			}, (result, data) => {

				if (result !== 'success') {
					this.$message.error('获取支付链接失败');
					return
				}

				if (data.code === 200 && data.msg.paymentUrl) {
					windowReference.location = data.msg.paymentUrl;
				}
			})
		},
		onClosePaymentModal() {
			this.showPaymentMethodsModal = false
		},
		updateSubscription() {
			getRequest('airport/subscription/getMySubscriptionInfo', (result, data) => {

				if (!data) {
					return
				}

				if (data.code === 200 && data.msg.planType) {
					this.$message.success('刷新订阅信息成功');
					this.isSubscriptionAvailable = true
					this.bandwidthLimit = data.msg.bandwidthLimit
					this.planType = data.msg.planType
					this.bandwidthUsage = data.msg.bandwidthUsage
					this.planExpireTimestamp = data.msg.planExpireTimestamp
					this.bandwidthResetDay = data.msg.bandwidthResetDay
				}
			})
		},
		getNextBandwidthRestTime() {
			if (this.nextBandwidthResetTime) {

				return formatDateString(this.nextBandwidthResetTime)
			}
			return `每月 ${this.bandwidthResetDay} 日 上午 8:00`
		},
		getCoupon() {
			let cookieId = localStorage.getItem('cookieId')
			if (!cookieId) {
				cookieId = v4()
				localStorage.setItem('cookieId', cookieId)
			}
			postRequest('airport/subscription/getCoupon', {cookieId: cookieId}, (result, data) => {
				if (!data) {
					return
				}
				if (data.code === 200 && data.msg.couponId) {
					this.couponId = data.msg.couponId
					if (data.msg.couponType === 'ONE_DAY') {
						this.couponText = '您有一张体验券，可免费使用一天.'
						return
					}
					if (data.msg.couponType === 'ONE_MONTH') {
						this.couponText = '尊敬的星云VIP用户，您有一张月卡券。'
						return
					}
					if (data.msg.couponType === 'TWO_MONTH') {
						this.couponText = '尊敬的星云VIP用户，您有一张两个月使用券。'
						return
					}
					if (data.msg.couponType === 'THREE_MONTH') {
						this.couponText = '尊敬的星云VIP用户，您有一张三个月使用券。'
						return
					}
				}
			})
		},
		redeemCoupon() {
			if (!this.couponId) {
				return
			}
			postRequest('airport/subscription/redeemCoupon', {couponId: this.couponId}, (result, data) => {
				if (!data) {
					return
				}

				if (data.code === 202) {
					this.$message.success('体验券已被使用');
					this.refreshInfo()
				}
				if (data.code === 200) {
					this.$message.success('兑换成功');
					this.refreshInfo()
				}
			})

		},
		refreshInfo() {
			getRequest('airport/subscription/getMySubscriptionInfo', (result, data) => {

				if (!data || data.code !== 200 || !data.msg.planType) {
					this.isSubscriptionAvailable = false
					this.getCoupon()
					return
				}

				if (data.code === 200 && data.msg.planType) {
					// console.log(`payment url: ${data.msg.paymentUrl}`)
					this.isSubscriptionAvailable = true
					this.bandwidthLimit = data.msg.bandwidthLimit
					this.planType = data.msg.planType
					this.bandwidthUsage = data.msg.bandwidthUsage
					this.planExpireTimestamp = data.msg.planExpireTimestamp
					this.bandwidthResetDay = data.msg.bandwidthResetDay
					this.email = data.msg.email
					this.nextBandwidthResetTime = data.msg.nextBandwidthResetTime
					const now = Date.now()
					this.isPlanExpired = now > parseInt(this.planExpireTimestamp) * 1000;
					return
				}

			})
			// getRequest('airport/subPlans/getSubPlans', (result, data) => {
			//
			//
			// 	if (result !== 'success') {
			// 		this.$message.error('获取订阅套餐失败');
			// 		return
			// 	}
			// 	if (!data) {
			// 		return
			// 	}
			// 	if (data.code === 201) {
			// 		this.$message.error('用户验证失败');
			// 		localStorage.removeItem('sessionId');
			// 		localStorage.removeItem('lastVerify')
			// 		this.$router.push('/login');
			// 		return
			// 	}
			// 	if (data.code === 300 || data.code === 301) {
			// 		this.$message.error('获取订阅套餐失败');
			// 		return
			// 	}
			//
			// 	if (data.code === 200) {
			// 		this.planList = data.msg.subPlans
			// 		localValue.subPlans = data.msg.subPlans
			// 		localValue.subPlanUpdateTimestamp = parseInt(new Date().getTime() / 1000)
			// 	}
			// })
			getRequest('airport/subscription/getMySubscriptionLink', (result, data) => {

				if (!data) {
					return
				}
				if (data.code === 201) {
					this.$message.error('用户验证失败');
					localStorage.removeItem('sessionId');
					localStorage.removeItem('lastVerify')
					this.$router.push('/login');
					return
				}
				if (data.code === 203) {
					this.$message.error('订阅已过期');
					return
				}
				if (data.code === 300 || data.code === 301) {
					this.$message.error('获取订阅链接失败');
					return
				}

				if (data.code === 200) {
					this.subscriptionLink = data.msg.subscriptionLink
					// const canvas = document.getElementById('canvas')

					// QRCode.toCanvas(canvas, data.msg.subscriptionLink, function (error) {
					// 	if (error) {
					// 		console.error(error)
					// 		return
					// 	}
					// 	console.log('success!');
					// })
					this.createQrCode(this.subscriptionLink)
				}
			})
		},
		createQrCode(link, time = 1, maxTime = 5) {
			if (time >= maxTime) {
				return
			}
			const canvas = document.getElementById('canvas')
			if (!canvas) {
				setTimeout(() => {
					this.createQrCode(link, ++time, maxTime)
				}, 1000)
				return
			}
			QRCode.toCanvas(canvas, link, function (error) {
				if (error) {
					console.error(error)
					return
				}
				console.log('success!');
			})
		}
	}
}
</script>

<style scoped>
.my-sub-empty-icon {
	width: 16em;
	opacity: 0.6;
}

.my-sub-empty-text {
	margin-top: 3em;
	opacity: 0.6;
	color: #E6D5B8;
	font-size: 1.2em;
}

.my-sub-go-to-buy {
	margin-top: 2em;
	direction: rtl;
}

.my-sub-go-to-buy-btn-icon {
	margin-left: 0.6em;
}

.subscription-display-row {
	color: #E6D5B8;
	font-size: 1.2em;
	padding: 0.2em 0;
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}

.subscription-display-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: start;
	padding: 0em 3em;
}

.subscription-summary-container {
	border: 1px solid #FF8900;
	padding: 1em 2em;
	border-radius: 0.4em;
}

.subscription-link-title {
	color: #FF8900;
	font-size: 1.6em;
}

.subscription-link-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	color: #FF8900;
}

.client-app-table {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.client-app-row {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
}

.client-app-table-os {
	width: 3em;
}

.client-app-download {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 0.2em;
	color: #E45826;
	font-size: 1.2em;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.client-app-table-title {
	display: flex;
	padding: 0 3em;
	height: 4em;
}

.client-app-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.copy-subscription-link-btn {
	margin-left: 1em;
}

@media only screen and (max-width: 900px) {
	.client-app-table {
		display: grid !important;
		grid-template-columns: repeat(1, 1fr) !important;
		margin-bottom: 3em;
	}

	.subscription-link-row {
		flex-direction: column;
		width: 100%;
	}

	.subscription-link-wrapper {
		width: inherit !important;
		text-align: start !important;
	}

	.copy-subscription-link-btn {
		margin-left: 0 !important
	}

	.qr-canvas-wrapper {
		text-align: center !important;
		width: 100% !important;
	}

	.bandwidth-usage-container {
		flex-direction: column !important;
		align-items: start !important;
	}
}

.subscription-link-wrapper {
	word-wrap: break-word;
	word-break: break-all;
	text-align: start;
}

.extend-plan-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.mac-download-system-pick-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.sub-link-attention {
	color: #FF8900;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.sub-coupon-text {
	color: #E6D5B8;
	font-size: 1.2em;
	margin-top: 2em;
}

.client-app-table-title-container{
	margin: 0.2em 0;
	background-color: rgba(228, 88, 38, 0.15);
	padding: 0.4em 0;
	margin-right: 0.4em;
}

.app-download-container{
	cursor: pointer;
	box-sizing: border-box;
}

.app-download-container:hover {
	border: 1px solid #E45826;
	padding: 0.2em;
	margin-right: 0.4em;
}
</style>