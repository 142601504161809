let isLoading = false
let loadingEle = null
let toastBody = null

let server = null
import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import moment from 'moment-timezone';

const urlList = [
	'https://rev0.aizhiyuan.cc',
	'https://rev1.aizhiyuan.cc',
	'https://rev0.khljbzxpeytzgsqgtfki.cc',
	'https://rev1.khljbzxpeytzgsqgtfki.cc',
	'https://rev0.nebulabinaryproxy.win',
	'https://rev1.nebulabinaryproxy.win',
	'https://rev0.goalphacloud.com',
	'https://rev1.goalphacloud.com',
]

async function getServerUrl(index = 0) {
	if (server) {
		return
	}

	showLoading()

	try {
		const url = urlList[index]
		const result = await getRequestAsync(url + '/web/test', true)
		if (result?.ok === true) {
			const text = await result.text()
			server = url
		} else if (index < urlList.length) {
			const newIndex = index + 1
			await getServerUrl(newIndex)
		}
	} finally {
		hideLoading()
	}

}

function convertTimestampToTimeString(timestamp) {
	// 使用moment-timezone将时间戳转换为"Asia/Shanghai"时区的时间
	// 并格式化为常见的日期时间格式
	return moment(timestamp * 1000).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
}

async function getFingerprint() {
	const fp = await FingerprintJS.load({screen_resolution: true});

	const result = await fp.get();

	return result.visitorId;
}

function showToast(text) {

	if (toastBody) {
		document.body.removeChild(toastBody)
		toastBody = null
	}
	toastBody = document.createElement("div");
	toastBody.classList.add('sl-toast');
	toastBody.innerText = text
	document.body.append(toastBody)
	const timeoutID = window.setTimeout(() => {
		if (toastBody) {
			document.body.removeChild(toastBody)
			toastBody = null
		}
		window.clearTimeout(timeoutID)
	}, 3000);
}

function getRequest(url, callback) {
	showLoading()
	const myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this

	fetch(`${server}/${url}`, requestOptions)
		.then(response => {
			return response.text(); // 返回响应文本
		})
		.then(result => {
			const responseData = JSON.parse(result)
			console.log(responseData)
			if (responseData && responseData.code === 200) {
				callback('success', responseData)
			} else {
				callback('fail', responseData)
			}

		})
		.catch(error => {
			console.log(error)
			callback('error')
		}).finally(() => {
		hideLoading();
	});
}

async function getRequestAsync(url, isFullUrl = false) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this
	let response
	if (isFullUrl === true) {
		// requestOptions.mode = 'no-cors'
		response = await fetch(url, requestOptions)
		return response
	} else {
		response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

function postRequest(url, data, callback) {
	showLoading()
	var myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	fetch(`${server}/${url}`, requestOptions)
		.then(response => {
			return response.text(); // 返回响应文本
		})
		.then(result => {
			const responseData = JSON.parse(result)
			console.log(responseData)
			if (responseData && responseData.code === 200) {
				if(callback) {
					callback('success', responseData)
				}
			} else {
				if (callback) {
					callback('fail', responseData)
				}

			}

		})
		.catch(error => {

			console.log('error', error)
			callback('error', error)
		}).finally(() => {
		hideLoading();
	});
}

async function postRequestAsync(url, data, isFullUrl = false) {
	var myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this
	if (isFullUrl === true) {
		try {
			const response = await fetch(url, requestOptions)
			return response
		} catch (e) {
			return null
		}
	} else {
		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

function showLoading() {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function hideLoading() {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

let clickTimer = {}

function clickThrottle(clickType = 'default', interval = 3000) {
	let now = Date.now()
	if (!clickTimer[clickType]) {
		clickTimer[clickType] = 0
	}
	let lastClicked = clickTimer[clickType]
	if (now - lastClicked < interval) {
		return false
	} else {
		clickTimer[clickType] = now;
		return true
	}
}

function verifyEmail(email) {
	// const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.value === "") {
		return false;
	}
	return reg.test(email);
}

function isMobile() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true
	}
	return false
}

function fallbackCopyTextToClipboard(text) {
	let textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";


	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	document.execCommand("copy");
	let toReturn = ''
	try {
		let successful = document.execCommand('copy');
		let msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
		toReturn = successful === 'successful' ? 'success' : 'fail'
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
		toReturn = 'fail'
	}

	document.body.removeChild(textArea);
	return toReturn
}

function startDownload(url) {
	let a = document.createElement('a');
	a.setAttribute('download', '');// download属性
	a.setAttribute('href', url);// href链接
	a.click();// 自执行点击事件
	a = null;
}

function formatDateString(timestamp) {
	const date = new Date(timestamp * 1000);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${year}年${month}月${day}日 ${hours}:${minutes}`;
}

function isValidTRC20Address(address) {
	const trc20AddressRegex = /^(T|t)[A-Za-z0-9]{33}$/;

	return trc20AddressRegex.test(address);
}

function isValidBEP20Address(address) {
	const bep20AddressRegex = /^(0x)?[A-Fa-f0-9]{40}$/;

	return bep20AddressRegex.test(address);
}

function roundNumber(number, decimals) {
	return (number / 100).toFixed(2)
}

function extractDomain(url) {
	// 如果没有协议头，添加临时协议头以便使用 URL API
	if (!/^https?:\/\//i.test(url)) {
		url = 'https://' + url;
	}

	try {
		const domain = new URL(url).hostname;
		return domain.replace(/^www\./i, ''); // 移除 www. 前缀
	} catch {
		return null;
	}
}

function visitClassifyByUrl(list) {

	const classifiedDomain = {}
	for (const item of list) {
		const extractedDomain = extractDomain(item.url)
		if (!classifiedDomain[extractedDomain]) {
			classifiedDomain[extractedDomain] = {}
			classifiedDomain[extractedDomain][item.deviceType] = 1
			classifiedDomain[extractedDomain].count = 1

		} else {
			if (classifiedDomain[extractedDomain][item.deviceType]) {
				classifiedDomain[extractedDomain][item.deviceType]++
			} else {
				classifiedDomain[extractedDomain][item.deviceType] = 1
			}

			classifiedDomain[extractedDomain].count++
		}

	}
	return Object.entries(classifiedDomain).map(([tag, data]) => ({
		tag,
		count: data.count || 0,
		android: data.android || 0,
		windows: data.windows || 0,
		mac: data.mac || 0,
		ios: data.ios || 0
	})).sort((a, b) => b.count - a.count);
}

function generateDownloadType(list) {
	let android = 0, ios = 0, mac = 0, windows = 0
	for (const item of list) {
		if (item.actionType.indexOf('android') >= 0) {
			android++
		}
		if (item.actionType.indexOf('ios') >= 0) {
			ios++
		}
		if (item.actionType.indexOf('mac') >= 0) {
			mac++
		}
		if (item.actionType.indexOf('windows') >= 0) {
			windows++
		}
	}

	return {android, ios, mac, windows}

}

function generateDeviceType(list) {
	let android = 0, ios = 0, mac = 0, windows = 0
	for (const item of list) {
		if (item.deviceType?.indexOf('android') >= 0) {
			android++
		}
		if (item.deviceType?.indexOf('ios') >= 0) {
			ios++
		}
		if (item.deviceType?.indexOf('mac') >= 0) {
			mac++
		}
		if (item.deviceType?.indexOf('windows') >= 0) {
			windows++
		}
	}

	return {android, ios, mac, windows}

}

function registerListClassifyByReferCode(list) {
	if (!Array.isArray(list) || list?.length === 0) {
		return []
	}
	const toReturn = []
	for (const item of list) {
		const record = toReturn.find(record => record.referCode === item.referCode)
		if (record) {
			record.count++
		} else {
			toReturn.push({
				referCode: item.referCode,
				count: 1,
			})
		}


	}
	return toReturn.sort((a, b) => b.count - a.count).slice(0, 6)
}

function paymentRecordsClassifiedByReferCode(paymentRecords) {

	const toReturn = []

	for (const item of paymentRecords) {
		if (item.referCode) {
			const record = toReturn.find(a =>
				a.referCode === item.referCode
			)
			if (record) {
				record.count++
				record.amount += parseInt(item.amount) / 100
			} else {
				console.log(`referCode: ${item.referCode}`)
				toReturn.push({
					referCode: item.referCode,
					count: 1,
					amount: parseInt(item.amount) / 100
				})
			}
		}
	}

	return toReturn.sort((a, b) => {
		if (b.count === a.count) {
			return b.amount - a.amount
		}
		return b.count - a.count
	})

}

async function getSubscriptionPlans() {
	showLoading()
	const result = await getRequestAsync('airport/subPlans/getSubscribePlans')
	hideLoading()
	return {err: false, result}
}

async function reportVisit(actionType = 'web_visit_login') {
	const url = window.location.href;
	const fingerprint = await getFingerprint()
	await postRequestAsync('web/reportAirportVisit', {url, fingerprint, actionType})
}

function uploadFile(url, formData, uploadProgress, uploadSuccess, uploadFail) {
	const sessionId = localStorage.getItem('sessionId');
	if (!sessionId) {
		return
	}
	showLoading()
	axios.post(`${server}/${url}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'sessionid': sessionId
		},
		onUploadProgress: function (progressEvent) {
			// 计算上传进度的百分比
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			uploadProgress(percentCompleted)
			// console.log(`Upload progress: ${percentCompleted}%`);
			// 你可以在这里根据百分比来更新UI，例如进度条或者状态信息
		}
	}).then(response => {
		uploadSuccess(response.data)
	})
		.catch(error => {
			uploadFail()
		}).finally(() => {
		hideLoading()
	});
}

async function submitTicket(ticketSubject, ticketContent, ticketType, ticketId) {
	showLoading()
	const result = await postRequestAsync('airport/ticket/submitTicket', {
		ticketSubject,
		ticketContent,
		ticketType,
		ticketId
	})
	hideLoading()
	return {err: false, result}
}

async function getSubmittedTickets() {
	showLoading()
	const result = await getRequestAsync('airport/ticket/getSubmittedTicket')
	hideLoading()
	return {err: false, result}
}

async function getDividendInfo() {
	showLoading()
	const result = await getRequestAsync('airport/reference/getAvailableDividend')
	hideLoading()
	return {err: false, result}
}

async function withdrawDividend(vcode) {
	showLoading()
	const result = await postRequestAsync('airport/reference/withdrawDividend', {vcode})
	hideLoading()
	return {err: false, result}
}

async function getWalletInfo() {
	showLoading()
	const result = await getRequestAsync('airport/reference/getReceiverAddress')
	hideLoading()
	return {err: false, result}
}

async function sendWithdrawDividendVcode() {
	showLoading()
	const result = await getRequestAsync('airport/reference/sendWithdrawDividendVcode')
	hideLoading()
	return {err: false, result}
}

function getCurrentUrl() {
	return window.location.href
}

function fetchWithTimeout(url, options, timeout = 5000) {
	return Promise.race([
		fetch(url, options),
		new Promise((_, reject) =>
			setTimeout(() => reject(new Error('Request timeout')), timeout)
		)
	]);
}

export {
	showToast,
	showLoading,
	hideLoading,
	getRequest,
	postRequest,
	verifyEmail,
	startDownload,
	clickThrottle,
	formatDateString,
	isValidTRC20Address,
	isValidBEP20Address,
	roundNumber,
	postRequestAsync,
	getRequestAsync,
	visitClassifyByUrl,
	registerListClassifyByReferCode,
	generateDownloadType,
	generateDeviceType,
	paymentRecordsClassifiedByReferCode,
	getSubscriptionPlans,
	reportVisit,
	uploadFile,
	submitTicket,
	getSubmittedTickets,
	convertTimestampToTimeString,
	getDividendInfo,
	withdrawDividend,
	getWalletInfo,
	sendWithdrawDividendVcode,
	getCurrentUrl,
	getServerUrl,
	fetchWithTimeout
}