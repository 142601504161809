<template>
	<div class="login-page-main-container">
		<transition name="fade">
			<login-modal v-if="this.currentDisplay==='LOGIN'" @onNewUser="this.onRegisterNewUser"
			             @onForgotPassword="this.onForgotPassword" @onLoginSuccess="this.onLoginSuccess" @onSelectMailbox="onSelectMailbox"
			             :selectedMail="this.selectedMailbox"/>
		</transition>
		<transition name="fade">
			<register-modal v-if="this.currentDisplay==='REGISTER'" @goLogin="this.goLogin" :reg-selected-mail="this.selectedMailbox" @onSelectMailbox="onSelectMailbox"></register-modal>
		</transition>
		<transition name="fade">
			<reset-password-modal v-if="this.currentDisplay==='RESETPASSWORD'"
			                      @onLogin="this.goLogin"/>
		</transition>
		<SelectMailboxModal v-if="this.showSelectMailbox===true" :selected="this.selectedMailbox" @onCancel="closeSelectMailbox" @onConfirm="confirmSelectMailbox"/>

	</div>
</template>

<script>

import LoginModal from '../components/LoginModal.vue'
import RegisterModal from '../components/RegisterModal.vue'
import ResetPasswordModal from '../components/ResetPassword.vue'
import SelectMailboxModal from '../components/SelectMailboxModal.vue'
import {reportVisit} from "@/utils/methods";

export default {
	name: "LoginPage",
	components: {
		LoginModal,
		RegisterModal,
		ResetPasswordModal,
		SelectMailboxModal
	},
	data() {
		return {
			currentDisplay: 'LOGIN',
			showSelectMailbox: false,
			selectedMailbox: '@qq.com'
		}
	},
	async mounted() {
		await reportVisit('web_visit_login')
	},
	methods: {
		onNotificationOk() {
			this.showNotificationModal = false
		},
		onRegisterNewUser() {
			this.currentDisplay = 'REGISTER'
		},
		goLogin() {
			this.currentDisplay = 'LOGIN'
		},
		onForgotPassword() {
			this.currentDisplay = 'RESETPASSWORD'
		},
		onLoginSuccess() {
			this.$router.push('/dashboard')
		},
		onSelectMailbox() {
			this.showSelectMailbox = true
		},
		closeSelectMailbox() {
			this.showSelectMailbox = false
		},
		confirmSelectMailbox(selected) {
			this.selectedMailbox = selected
			this.showSelectMailbox = false
		}
	}
}
</script>

<style scoped>
.login-page-main-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-image: url('../images/matrix_bg.jpg');
	top: 0;
	left: 0;
}

</style>