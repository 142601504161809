<template>
	<div class="dashboard-main-container">
		<img class="dashboard-sub-view-wrapper-bg" src="../images/matrix_bg.jpg" alt="bg">
		<div class="dashboard-mobile-header">
			<img src="../images/logo.svg" alt="logo" class="dashboard-mobile-logo">
			<img src="../images/menu.svg" alt="menu" class="dashboard-mobile-menu" @click="toggleMobileMenu(true)">
		</div>
		<transition name="slide">
			<div class="dashboard-drawer-mask" v-if="isShowDrawer" @click="toggleMobileMenu(false)">
				<div class="dashboard-drawer-container">
					<div class="dashboard-drawer-wrapper">
						<div class="dashboard-drawer-menu-item"
						     :class="{'menu-activated': this.currentSelectedMenu === 1}"
						     @click="onMenuClicked('mysub')">
							<pushpin-outlined style="margin-right: 0.6em;"/>
							我的订阅
						</div>
						<div class="dashboard-drawer-menu-item"
						     :class="{'menu-activated': this.currentSelectedMenu === 2}"
						     @click="onMenuClicked('manual')">
							<book-outlined style="margin-right: 0.6em;"/>
							使用指南
						</div>
						<div class="dashboard-drawer-menu-item"
						     :class="{'menu-activated': this.currentSelectedMenu === 3}" @click="onMenuClicked('plan')">
							<paper-clip-outlined style="margin-right: 0.6em;"/>
							订阅套餐
						</div>
						<div class="dashboard-drawer-menu-item"
						     :class="{'menu-activated': this.currentSelectedMenu === 5}" @click="onMenuClicked('contact')">
							<contacts-outlined style="margin-right: 0.6em;"/>
							工单反馈
						</div>
						<a-popconfirm
							title="确认登出?"
							ok-text="确认"
							cancel-text="取消"
							class="airport-dashboard-popconfirm"
							placement="right"
							@confirm="doLogout"
							@cancel="cancel">
							<div class="dashboard-drawer-menu-item" @click="onMenuClicked('logout', $event)">
								<logout-outlined style="margin-right: 0.6em;"/>
								登出
							</div>
						</a-popconfirm>
					</div>
				</div>
			</div>
		</transition>

		<a-layout style="min-height: 100vh; position: relative; background-color: #191123;"
		          :class="{'blur': this.isShowDrawer === true}">

			<a-layout-sider v-model:collapsed="collapsed" collapsible style="background:rgba(0,0,0,0.42);"
			                class="layout-slider-container">
				<img class="dashboard-logo" src="../images/logo.svg" alt="logo">
				<a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline" style="background: transparent;">
					<a-menu-item key="1" @click="onMenuClicked('mysub')">
						<pushpin-outlined/>
						<span class="disable-select">我的订阅</span>
					</a-menu-item>
					<a-menu-item key="2" @click="onMenuClicked('manual')">
						<book-outlined/>
						<span class="disable-select">使用指南</span>
					</a-menu-item>
					<a-menu-item key="3" @click="onMenuClicked('plan')">
						<paper-clip-outlined/>
						<span class="disable-select">订阅套餐</span>
					</a-menu-item>
					<a-menu-item key="5" @click="onMenuClicked('contact')">
						<contacts-outlined/>
						<span class="disable-select">工单反馈</span>
					</a-menu-item>
<!--					<a-menu-item key="4" @click="onMenuClicked('referFriend')">-->
<!--						<usergroup-add-outlined/>-->
<!--						<span class="disable-select">推荐好友</span>-->
<!--					</a-menu-item>-->
<!--					<a-menu-item key="5" @click="onMenuClicked('referFriend')">-->
<!--						<contacts-outlined/>-->
<!--						<span class="disable-select">联系我们</span>-->
<!--					</a-menu-item>-->
					<a-popconfirm
						title="确认登出?"
						ok-text="确认"
						cancel-text="取消"
						class="airport-dashboard-popconfirm"
						placement="right"
						@confirm="doLogout"
						@cancel="cancel">
						<a-menu-item key="6" @click="onMenuClicked('logout', $event)">
							<logout-outlined/>
							<span class="disable-select">登出</span>
						</a-menu-item>
					</a-popconfirm>
				</a-menu>
			</a-layout-sider>
			<a-layout
				style="display: flex; width: 100%; flex-direction: column;justify-content: center;background: transparent;">
				<div class="dashboard-sub-view-wrapper">
					<router-view @goToBuy="goToBuy"></router-view>
				</div>
				<a-layout-footer
					style="text-align: center; padding: 8px 50px !important;background-color: #000000; color: #E6D5B8;z-index: 100;">
					星云仪表盘 ©{{ this.getFullYear() }} Created by Nebula Team
				</a-layout-footer>
			</a-layout>
		</a-layout>
		<NotificationModal v-if="showNotificationModal === true" @ok="onNotificationOk" :message=this.notificationMessage />
	</div>

</template>
<script>
import {
	BookOutlined,
	ContactsOutlined,
	LogoutOutlined,
	PaperClipOutlined,
	PushpinOutlined,
	UsergroupAddOutlined
} from '@ant-design/icons-vue';
import {defineComponent, ref} from 'vue';
import {getRequest, reportVisit} from "@/utils/methods";
import NotificationModal from "../components/NotificationModal.vue";

export default defineComponent({
	components: {
		// UsergroupAddOutlined,
		PaperClipOutlined,
		BookOutlined,
		PushpinOutlined,
		LogoutOutlined,
		ContactsOutlined,
		NotificationModal
	},
	data() {
		return {
			collapsed: ref(false),
			selectedKeys: ref(['1']),
			isShowDrawer: false,
			currentSelectedMenu: null,
			showNotificationModal: false,
			notificationMessage: '亲爱的用户您好，\n我们的服务器近期遭到了网络攻击，造成了数据丢失。如果您的会员信息丢失，请重新注册后将最后一次购买的截图发送至：george.gu@nebulabinary.com这个邮箱，客服这边会帮您处理。\n感谢您的理解和支持。\n\n星云团队'
		};
	},
	beforeMount() {
		const currentChildRoute = this.$route.matched[this.$route.matched.length - 1];

		if (currentChildRoute) {
			if (currentChildRoute.name === 'manual') {
				this.selectedKeys = ['2'];
				this.currentSelectedMenu = 2;
			} else if (currentChildRoute.name === 'mysub') {
				this.selectedKeys = ['1'];
				this.currentSelectedMenu = 1;
			} else if (currentChildRoute.name === 'plan') {
				this.selectedKeys = ['3'];
				this.currentSelectedMenu = 3;
			} else if (currentChildRoute.name === 'referFriend') {
				this.selectedKeys = ['4'];
				this.currentSelectedMenu = 4;
			} else if (currentChildRoute.name === 'contact') {
				this.selectedKeys = ['5'];
				this.currentSelectedMenu = 5;
			}
		}

	},
	async mounted() {
		await reportVisit('web_visit_dashboard')
	},
	beforeUpdate() {
		const currentChildRoute = this.$route.matched[this.$route.matched.length - 1];

		if (currentChildRoute) {
			if (currentChildRoute.name === 'manual') {
				this.selectedKeys = ['2'];
				this.currentSelectedMenu = 2;
			} else if (currentChildRoute.name === 'mysub') {
				this.selectedKeys = ['1'];
				this.currentSelectedMenu = 1;
			} else if (currentChildRoute.name === 'plan') {
				this.selectedKeys = ['3'];
				this.currentSelectedMenu = 3;
			} else if (currentChildRoute.name === 'referFriend') {
				this.selectedKeys = ['4'];
				this.currentSelectedMenu = 4;
			} else if (currentChildRoute.name === 'contact') {
				this.selectedKeys = ['5'];
				this.currentSelectedMenu = 5;
			}
		}
	},
	methods: {
		getFullYear() {
			return new Date().getFullYear();
		},
		onNotificationOk() {
			this.showNotificationModal = false;
		},
		onMenuClicked(type, event) {

			if (type === 'logout') {
				event.stopPropagation()
				return
			}
			if (type === 'manual') {
				this.$router.push('/dashboard/manual');
				return
			}
			if (type === 'mysub') {
				this.$router.push('/dashboard/mysub');
				return
			}
			if (type === 'plan') {
				this.$router.push('/dashboard/plan');
				return
			}
			if (type === 'referFriend') {
				this.$router.push('/dashboard/referFriend');
				return
			}

			if (type === 'contact') {
				this.$router.push('/dashboard/contact');
				return
			}

		},
		goToBuy() {
			this.$router.push('/dashboard/plan');
			this.selectedKeys = ['3'];
		},
		doLogout(event) {
			event.stopPropagation()
			getRequest('airport/auth/logout', (result, data) => {
				this.$message.success('登出成功');
				localStorage.removeItem('sessionId')
				localStorage.removeItem('lastVerify')
				this.$router.push('/login');
			})
		},
		toggleMobileMenu(value) {
			console.log('openMobileMenu')
			this.isShowDrawer = value
		}
	}
});
</script>
<style>
#components-layout-demo-side .logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
	background: #141414;
}

.dashboard-logo {
	width: 1.8em;
	padding: 0.6em 0;
}

.dashboard-sub-view-wrapper {
	flex: 1;
	position: relative;
	overflow: hidden;
}

.dashboard-sub-view-wrapper-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.1;
	filter: blur(1px);
}

.ant-layout-sider-trigger {
	color: #E6D5B8 !important;
	background-color: transparent !important;
}

.ant-menu-title-content {
	color: #E6D5B8 !important;
}

@media only screen and (max-width: 900px) {
	.layout-slider-container {
		display: none !important;
	}

	.dashboard-mobile-header {
		position: fixed;
		display: flex;
		background-color: rgba(0, 0, 0, 0.8);
		height: 3em;
		z-index: 10000;
		width: 100%;
		top: 0;
	}

	.dashboard-mobile-logo {
		width: 1.6em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.dashboard-mobile-menu {
		position: absolute;
		width: 1.6em;
		top: 50%;
		right: 0.8em;
		transform: translate(0, -50%);
		display: block;
		cursor: pointer;
		z-index: 10001;
	}

	.dashboard-main-container {
		padding-top: 3em !important;
	}

	.subscription-display-container {
		padding: 0 1.6em !important;
	}

	.dashboard-drawer-container {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 10002;
		width: 12em;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.dashboard-drawer-wrapper {
		display: flex;
		flex-direction: column;
		align-items: start;
		margin-top: 2em;
	}

	.dashboard-drawer-menu-item {
		color: #E6D5B8;
		font-size: 1.4em;
		padding: 0.6em;
		width: 100%;
		text-align: start;
	}

	.dashboard-drawer-mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10000;
	}

	.menu-activated {
		background-color: #FF8900;
		color: #ffffff !important;
	}
}

@media only screen and (min-width: 901px) {
	.dashboard-mobile-header {
		display: none;
	}
}

.dashboard-main-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
}


</style>