import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {postRequest,getServerUrl} from '@/utils/methods'
import Antd from 'ant-design-vue';
import store from '@/utils/store';
import './theme.less';
import 'core-js/stable'
import 'core-js/es/promise'
// @import '~ant-design-vue/dist/antd.less';

const app = createApp(App);
router.beforeEach(async (to, from, next) => {
	try {
		// 等待获取服务器URL
		await getServerUrl()

		// 直接放行的页面
		const publicPages = ['referencePage', 'customerServicePage', 'statisticPage', 'statisticDisplayPanel']
		if (publicPages.includes(to.name)) {
			return next()
		}

		// 验证会话
		const sessionId = localStorage.getItem('sessionId')
		const lastVerify = localStorage.getItem('lastVerify')
		const now = Math.floor(Date.now() / 1000)

		// 如果在24小时验证期内
		if (lastVerify && (now - parseInt(lastVerify) < 24 * 3600)) {
			return next()
		}

		// 登录页面直接放行
		if (to.name === 'login') {
			return next()
		}

		// 没有sessionId，跳转到登录页
		if (!sessionId) {
			return next({ name: 'login' })
		}

		// 验证sessionId
		// 将回调形式改为Promise形式
		const verifySession = () => {
			return new Promise((resolve, reject) => {
				postRequest('airport/auth/verifySessionId', { sessionId }, (result, data) => {
					if (result === 'success') {
						resolve()
					} else {
						reject()
					}
				})
			})
		}

		try {
			await verifySession()
			localStorage.setItem('lastVerify', now.toString())
			return next()
		} catch {
			localStorage.removeItem('sessionId')
			localStorage.removeItem('lastVerify')
			return next({ name: 'login' })
		}

	} catch (error) {
		console.error('Navigation guard error:', error)
		return next({ name: 'login' })
	}
})
app.use(router); // 使用Vue Router插件
app.use(Antd)
app.use(store)

app.mount('#app');